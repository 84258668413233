<template>
  <div
    class="screensaver"
    ref="screensaver"
  >

    <!-- screensaver:headline & description START -->
    <div
      class="headline-description"
      v-on:click="closeScreensaver"
    >
      <div class="headline" v-html="$t('terminal.screensaver.headline')"></div>
      <div class="description" v-html="$t('terminal.screensaver.description')"></div>
    </div>
    <!-- screensaver:headline & description END -->

    <!-- screensaver:button(start) START -->
    <div
      class="start"
      v-on:click="closeScreensaver"
    >
      <div class="text" v-html="$t('terminal.screensaver.buttons.start')"></div>
      <particles />
    </div>
    <!-- screensaver:button(start) END -->

    <!-- screensaver:info START -->
    <div
      v-on:click="closeScreensaver"
      class="info"
      v-html="$t('terminal.screensaver.excerpt')"
    ></div>
    <!-- screensaver:info END -->

    <!-- help START -->
    <div
      :class="[
        'help',
      ]"
    >
      <div class="help-inner">
        <div class="description">
          <p v-html="$t('terminal.index.help.description')"></p>
        </div>
        <div class="actions">
          <div
            class="btn btn-default btn-default-white"
            v-on:click="dialogOpen()"
            v-on:touchstart="touchStart($event)"
            v-on:touchend="touchEnd($event);"
            v-on:touchcancel="touchEnd($event)"
            v-html="$t('terminal.index.help.manual')"
          ></div>
        </div>
      </div>
    </div>
    <!-- help END -->

    <!-- help:modal START -->
      <dialog-default
        :title="dialogTitle"
        :headline="dialogHeadline"
        :description="dialogDescription"
        :type="dialogContentType"
        :dialogVisible="dialogVisible"
        :dialogClose="dialogClose"
      />
      <!-- help:modal END -->
  </div>
</template>

<script>
export default {
  name: 'screensaver',
  components: {
    DialogDefault: () => import('../components/shared/dialog/Default.vue'),
    Particles: () => import('../components/screensaver/Particles.vue'),
  },
  data() {
    return {
      /**
       * Dialog
       */
      dialogVisible: false,
      dialogTitle: this.$t('terminal.index.dialog.title'),
      dialogHeadline: this.$t('terminal.index.dialog.headline'),
      dialogDescription: this.$t('terminal.index.dialog.description'),
      dialogContentType: 'manual',
    };
  },
  created() {
    if (this.$route.query.token && this.$route.query.locale) {
      localStorage.setItem('boxToken', this.$route.query.token);
      localStorage.setItem('boxLang', this.$route.query.locale);
    }
    if (this.$route.query.showQrcodeTest) {
      localStorage.setItem('showQrcodeTest', true);
    } else {
      localStorage.setItem('showQrcodeTest', false);
    }
  },
  methods: {
    closeScreensaver() {
      this.$refs.screensaver.classList.add('closing');
      this.createSession(localStorage.getItem('boxToken'), localStorage.getItem('boxLang'));
    },

    createSession(token, locale) {
      const formData = new FormData();
      formData.append('token', token);
      formData.append('locale', locale);
      this.$http.post(`${process.env.VUE_APP_BASE_URL}/createTerminalSession`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        },
      })
        .then((response) => {
          if (response.data) {
            const user = response.data;
            const auth = user.token;

            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', auth);
            localStorage.setItem('auth', auth);

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.user = user;
            this.$store.commit('SET_USER', user);
            localStorage.setItem('user', JSON.stringify(user));

            // clear input
            setTimeout(() => {
              this.$router.push('/cart');
            }, 1000);
          }
        })
        .catch((error) => {
          this.loginError = true;
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            console.clear();

            this.errorStatus = error.response.data;
            this.loadingVisible = false;
            this.serverErrorMessages = this.errorStatus.errors;

            this.serverErrorMessages.forEach((message) => {
              this.error(`${this.$t(`error.${message.code}`)}`);
            });

            this.$router.push('/');

            // reset login error variable
            setTimeout(() => {
              this.loginError = false;
            }, 3000);

            // delete created notification dom element
            setTimeout(() => {
              if (document.querySelector('.el-notification')) {
                const elem = document.querySelector('.el-notification');
                elem.remove();
              }
            }, 3500);
          }
        });
    },

    dialogOpen() {
      this.dialogVisible = true;
    },

    dialogClose() {
      this.dialogVisible = false;
    },

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },

    error(message) {
      if (message) {
        this.$notify({
          message: this.$t(`${message}`),
          type: 'error',
          position: 'bottom-right',
          showClose: false,
          duration: 3000,
        });
      } else {
        this.$notify({
          message: '',
          type: 'error',
          position: 'bottom-right',
          showClose: false,
          duration: 3000,
        });
      }
    },
  },
};
</script>
